module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eric Wätke","short_name":"Eric Wätke","start_url":"/","background_color":"#1a1b1f","theme_color":"#F40000","display":"browser","icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"185161baedd95caff872457a47adf2d7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"ericwaetke.com","customDomain":"plausible.wovenspace.xyz/js/plausible.outbound-links.js?original="},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
