// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-de-js": () => import("./../../../src/pages/about.de.js" /* webpackChunkName: "component---src-pages-about-de-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-blog-de-js": () => import("./../../../src/pages/blog.de.js" /* webpackChunkName: "component---src-pages-blog-de-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-girrbachs-js": () => import("./../../../src/pages/girrbachs.js" /* webpackChunkName: "component---src-pages-girrbachs-js" */),
  "component---src-pages-imprint-de-js": () => import("./../../../src/pages/imprint.de.js" /* webpackChunkName: "component---src-pages-imprint-de-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-de-js": () => import("./../../../src/pages/resources.de.js" /* webpackChunkName: "component---src-pages-resources-de-js" */),
  "component---src-pages-resources-en-js": () => import("./../../../src/pages/resources.en.js" /* webpackChunkName: "component---src-pages-resources-en-js" */),
  "component---src-pages-work-de-js": () => import("./../../../src/pages/work.de.js" /* webpackChunkName: "component---src-pages-work-de-js" */),
  "component---src-pages-work-en-js": () => import("./../../../src/pages/work.en.js" /* webpackChunkName: "component---src-pages-work-en-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */)
}

